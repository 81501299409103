html {
  font-size: 62.5%;
}

html,
body {
  font-family: sans-serif;
  scroll-behavior: smooth;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  font-size: 1.6rem;
  width: 100%;
  margin: 0 auto;
}

.jm-cookieBanner {
  width: 100%;
  height: 8rem;
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: rgba($color: #2c3e50, $alpha: 0.7);
  color: white;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  a {
    color: white;
  }

  button {
    -webkit-appearance: none;
    cursor: pointer;
    border: none;

    background-color: #ffd603;

    padding: 1rem 2rem;
    font-size: 1.6rem;
    border-radius: 0.5rem;
    margin-left: 5rem;
    transition: all 0.3s;

    &:hover {
      background-color: #ff8709;
    }

    &:focus {
      outline: none;
    }
  }
}

@import "./components/utility";
@import "./ReactDatepickerStyle";
