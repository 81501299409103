.footer {
  background-color: #34495e;
  height: 6rem;
  margin-top: 6rem;
  padding: 2rem;
  text-align: center;
  color: #ecf0f1;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding-bottom: 3rem;

  a {
    color: #ecf0f1;
    text-decoration: none;
    font-size: 1.2rem;

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
