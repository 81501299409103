.react-datepicker {
  font-size: 1em !important;
  button {
    -webkit-appearance: button !important;
  }
}
.react-datepicker__header {
  padding-top: 0.8em !important;
}
.react-datepicker__month {
  margin: 0.4em 1em !important;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em !important;
  line-height: 1.9em !important;
  margin: 0.166em !important;
}
.react-datepicker__current-month {
  font-size: 1em !important;
}
.react-datepicker__navigation {
  top: 1em !important;
  line-height: 1.7em !important;
  border: 0.45em solid transparent !important;
}
.react-datepicker__navigation--previous {
  border-right-color: #ccc !important;
  left: 1em !important;
}
.react-datepicker__navigation--next {
  border-left-color: #ccc !important;
  right: 1em !important;
}

.react-datepicker__input-container {
  input {
    -webkit-appearance: none;
    padding: 1rem 1rem;
    font-size: 1.6rem;
    border: 3px solid black;
    border-radius: 5px;

    &:focus {
      outline: none;
    }
  }
}
