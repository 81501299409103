.goBackButton {
  position: absolute;
  top: 2rem;
  left: 2rem;
  color: white;
  font-size: 2.5rem;
  letter-spacing: 2px;
  text-decoration: none;
  cursor: pointer;
}

.jm__blogPost {
  overflow-x: hidden;
}

.jm_blogPostHeader {
  min-height: 80vh;
  height: fit-content;
  text-align: center;
  font-size: 6rem;
  display: grid;
  justify-content: center;
  align-items: center;

  background: #fdc830;
  background: -webkit-linear-gradient(to right, #f37335, #fdc830);
  background: linear-gradient(to right, #f37335, #fdc830);
  color: white;
  font-family: "Roboto", sans-serif;
  letter-spacing: 1px;

  h1 {
    margin: 0;
    padding: 1.5rem 2.5rem;
  }
}

.jm_blogPostSubHeader {
  font-size: 2rem;
  padding: 3rem 6rem 3rem 6rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ecf0f1;
}

.jm__blogPostContentAuthorInfo {
  grid-area: authorinfo;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15rem;

  & .authorImage {
    height: 10rem;
    width: 10rem;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 3rem;
  }

  & .authorBio {
    padding: 3rem 6rem 0 6rem;
  }
}

.jm__blogPostContent {
  display: grid;
  grid-template-columns: 35% 30% 35%;
  grid-template-rows: 20% 80%;
  justify-content: center;
  grid-template-areas:
    ". postcontent authorinfo"
    ". postcontent .";
}

.jm__blogPostContentMD {
  grid-area: postcontent;
  font-size: 2rem;
  font-family: "Roboto", sans-serif;
}

@media screen and (max-width: 2000px) {
  .jm__blogPostContent {
    grid-template-columns: 30% 40% 30%;
  }
}

@media screen and (max-width: 1080px) {
  .jm__blogPostContent {
    grid-template-columns: 25% 50% 25%;
  }
}

@media screen and (max-width: 1080px) {
  .jm__blogPostContent {
    grid-template-rows: max-content;
    grid-template-areas:
      ". postcontent ."
      ". authorinfo .";

    & .authorBio {
      padding: 0;
      padding-top: 3rem;
    }
  }
}

@media screen and (max-width: 830px) {
  .jm__blogPostContent {
    grid-template-columns: 20% 60% 20%;
  }

  .jm__blogPostContentAuthorInfo {
    margin-top: 3rem;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: -2rem;
      left: 0;
      width: 100%;
      height: 0.5rem;
      border-radius: 1rem;
      background-color: darken($color: #fff, $amount: 5);
    }
  }

  .jm_blogPostHeader {
    min-height: 60vh;
    font-size: 4.5rem;
  }

  .jm_blogPostSubHeader {
    flex-direction: column;
    //align-items: flex-start;

    & div {
      text-align: center;
    }

    & div:not(:last-child) {
      margin-bottom: 3rem;
    }
  }
}

@media screen and (max-width: 620px) {
  .jm_blogPostHeader {
    min-height: 55vh;
    font-size: 4rem;
  }

  .jm__blogPostContent {
    grid-template-columns: 15% 70% 15%;
  }
}

@media screen and (max-width: 500px) {
  .jm_blogPostHeader {
    min-height: 50vh;
    font-size: 3rem;
  }
}

@media screen and (max-width: 390px) {
  .jm__blogPostContent {
    grid-template-columns: 5% 90% 5%;
  }
}
