.closeIcon {
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bolder;
  background-color: rgba($color: #2c3e50, $alpha: 1);
  cursor: pointer;
  font-size: 1rem;

  i {
    padding: 0.3rem;
  }
}
