.filterButton {
  background-color: #2c3e50;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 7rem;
  transition: all 0.2s;

  i.la-times {
    display: none;
  }

  &[data-single="true"] {
    right: 0 !important;
  }

  &--opened {
    transform: rotate(90deg);

    i.la-filter {
      display: none;
    }

    i.la-times {
      display: inline;
    }
  }
}

.addPostButton {
  background-color: #34495e;
  position: absolute;
  top: 0;
  z-index: 1;
  right: 14rem;
}

.loggeIn-btn {
  background-color: rgba(255, 135, 9, 0.9);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  user-select: none;
  transition: all 0.3s;

  &:hover {
    transform: rotate(90deg);
    background-color: darken($color: rgba(255, 135, 9, 0.9), $amount: 10);
  }

  &.active {
    transform: rotate(45deg);
    background-color: darken($color: rgba(255, 135, 9, 0.9), $amount: 10);
  }
}

.btn-round {
  border-radius: 50%;
  height: 6rem;
  width: 6rem;
  display: grid;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: bold;
  cursor: pointer;
  color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.subNav-btn-wrapper {
  position: fixed;
  right: 3rem;
  bottom: 9rem;
}

svg {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 600px;
  border-radius: 125px 125px 0 0;
}
