$applyButtonsHeight: 6rem;

.filterMenu {
  position: fixed;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background-color: #ecf0f1;
  z-index: 500;
  top: 0;
  user-select: none;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.swipeDown {
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 4rem;
  position: relative;
  box-shadow: 0 14px 28px -28px rgba(0, 0, 0, 0.25),
    0 10px 10px -10px rgba(0, 0, 0, 0.22);
  background-color: white;
  z-index: 2;
}

.content_wrapper {
  display: flex;
  flex: 1;
  min-height: 0px;
}

.overflow_container {
  flex: 1;
  overflow: auto;
  height: 100%;
  height: calc(var(--vh, 1%) * 100);
}

.activeFilter {
  background-color: white;
  padding: 3rem 0 2rem 2rem;
  h2 {
    margin: 0;
    margin-bottom: 2rem;
  }
}

.item {
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #2c3e50;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  div {
    margin-left: 0.5rem;
  }
}

.removeAllFilters {
  cursor: pointer;
  font-style: italic;
  font-size: 2rem;
  padding-left: 1rem;
  margin-left: 1rem;
  border-left: 1px solid gray;
}

.filterGroupWrapper {
  margin-bottom: calc((var(--vh, 1vh) * 2) + 10rem + #{$applyButtonsHeight});
}

.filterGroup {
  background-color: white;
  padding: 2rem 3rem;
  box-shadow: 0 14px 28px -28px rgba(0, 0, 0, 0.25),
    0 10px 10px -10px rgba(0, 0, 0, 0.22);

  &:first-child {
    margin-top: 4rem;
  }

  &:last-child {
    margin-bottom: 4rem;
  }

  &:not(:last-child) {
    margin-bottom: 4rem;
  }

  &_dateFilter {
    display: flex;
    flex-direction: column;
    font-size: 1.6rem;
    font-weight: bold;

    &_dateWrapper {
      display: flex;
      flex-wrap: wrap;
    }

    &_from {
      margin-bottom: 3rem;
      margin-right: 5rem;
    }

    p {
      margin: 0;
      margin-bottom: 0.5rem;
    }
  }
}

.filterButton {
  -webkit-appearance: none;
  background-color: #34495e;
  color: white;
  border: none;
  padding: 1rem 2rem;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  cursor: pointer;
  transition: all 0.2s;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:disabled {
    background-color: darken(#34495e, 15);
  }
}

.applyFilter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: $applyButtonsHeight;
  background-color: white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  z-index: 100;

  button:first-child {
    -webkit-appearance: none;
    background-color: white;
    color: black;
    font-weight: bold;
    border: 3px solid black;
    padding: 1rem 3rem;
    font-size: 1.4rem;
    cursor: pointer;
  }
  button:last-child {
    -webkit-appearance: none;
    cursor: pointer;
    background-color: black;
    color: white;
    font-weight: bold;
    border: none;
    padding: calc(1rem + 3px) calc(3rem + 3px);
    font-size: 1.4rem;
  }
}
