.blogPostPreview {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 1rem 2rem;

  &:not(:last-child) {
    margin-bottom: 5rem;
    border-radius: 0.5rem;
  }
}

.heading {
  display: flex;
  align-items: center;

  .authorAvatar {
    border-radius: 50%;
    object-fit: cover;
    width: 5rem;
    height: 5rem;
    margin-right: 2rem;
  }

  .authorName {
    margin: 0;
    margin-bottom: 0.5rem;
  }

  .dateContainer {
    color: gray;
    font-size: 1.6rem;
  }
}

.openPostDiv {
  cursor: pointer;
}
