.u-fullSizeModal {
  position: fixed;
  height: calc(var(--vh, 1vh) * 100);
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: grid;
  align-items: center;
  padding: 0 2rem;
  width: calc(100% - 4rem);

  &__wrapper {
    background-color: white;
    padding: 2rem 2rem;
    height: fit-content;
    font-size: 1.6rem;
    border-radius: 0.5rem;
  }

  button {
    color: white;
    -webkit-appearance: none;
    border: none;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    border-radius: 0.5rem;

    &:nth-of-type(2) {
      background: #ed213a;
      background: linear-gradient(to right, #93291e, #ed213a);
      color: white;
      font-weight: bold;
    }

    &:nth-of-type(1) {
      color: #2c3e50;
      font-weight: bold;
      background: #b3ffab;
      background: linear-gradient(to right, #12fff7, #b3ffab);
    }

    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
}
