.navigation {
  display: flex;
  justify-content: space-evenly;
  margin: 0 auto;
  align-items: center;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05);
  height: 6rem;
  div {
    &:not(:last-child) {
      margin-right: 2rem;
    }

    a {
      font-size: 2rem;
      text-decoration: none;
      color: black;
    }
  }
}
