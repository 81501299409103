.noPosts {
  display: grid;
  justify-content: center;
  align-items: center;
}

.content {
  display: grid;
  justify-content: center;
  align-items: flex-start;
  margin-top: 4rem;
}

.userPostWrapper {
  width: 72rem;
}

.userPost {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .postButtons {
    margin-left: 8rem;

    a {
      margin-right: 1rem;
    }

    .btn {
      user-select: none;
      color: white;
      background-color: black;
      padding: 1rem 2rem;
      border-radius: 0.5rem;
      text-decoration: none;
      font-size: 1.6rem;

      &.btn_red {
        background-color: red;
      }
    }
  }
}
