.authorContent {
  max-width: 73rem;
  margin: 0 auto;
  padding: 0 2rem;
  display: grid;
  grid-template-areas:
    "name picture"
    "bio picture"
    "social ."
    "articles articles";
  justify-items: center;
  align-items: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.authorName {
  grid-area: name;
}

.authorPicture {
  grid-area: picture;
  border-radius: 50%;
  object-fit: cover;
  height: 20rem;
  width: 20rem;
}

.authorBio {
  grid-area: bio;
}

.authorPosts {
  grid-area: articles;
  padding: 4rem 0 0 0;

  hr {
    margin-bottom: 6rem;
  }
}

.socialLinks {
  grid-area: social;
}
