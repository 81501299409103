.createPost {
  margin: 0 auto;
  background-color: #34495e;
  min-height: calc(var(--vh, 1vh) * 100);
  text-align: center;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.newPostForm {
  color: white;
}

.postPublish_success {
  color: #12fff7;
}

.postPublish_error {
  color: #ed213a;
}

.heading {
  display: inline-block;
  margin: 0;
  color: white;
  padding-top: 3rem;
  margin-bottom: 2rem;
  position: relative;
  padding-bottom: 5px;

  &::after {
    content: "";
    position: absolute;
    display: block;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.5rem;
    background: #fe8c00;
    background: linear-gradient(to right, #f83600, #fe8c00);
  }
}

.formGroup {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 95%;

  button {
    -webkit-appearance: none;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
    padding: 1rem 2rem;
    font-size: 1.4rem;
    border-radius: 0.5rem;
    font-size: 2.5rem;
    font-family: "Montserrat", sans-serif;

    &:focus {
      outline: none;
    }

    &:hover {
      box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25),
        0 10px 10px rgba(0, 0, 0, 0.22);
    }
  }

  textarea,
  input,
  select {
    width: 100%;
    border: none;
    border-radius: 0.5rem;
    box-sizing: border-box;
    font-size: 2rem;
    background-color: white;

    &:focus {
      outline: none;
    }
  }

  input,
  select {
    margin-top: 1rem;
    height: 4rem;
  }

  .postContent {
    height: calc(var(--vh, 1vh) * 65);
    padding: 1rem 2rem;
    resize: none;
  }

  .description {
    height: 20rem;
    resize: none;
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }
}

.formLabel {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.markdownPreview {
  box-sizing: border-box;
  background-color: white;
  border-radius: 0.5rem;
  color: black;
  padding: 1rem 2rem;
  width: 100%;
  min-height: calc(var(--vh, 1vh) * 65);
  text-align: left;

  pre {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    border-radius: 0.5rem;
  }
}

.btn_preview {
  margin-top: 2rem;
  color: white;
  letter-spacing: 1px;
  background: #fe8c00;
  font-weight: bold;
  background: linear-gradient(to right, #f83600, #fe8c00);
}

.btnGroup {
  flex-direction: row;
  margin-bottom: 2rem;

  button {
    background: #ed213a;
    background: linear-gradient(to right, #93291e, #ed213a);
    color: white;
    font-weight: bold;
  }

  button:not(:last-child) {
    color: #2c3e50;
    background: #b3ffab;
    background: linear-gradient(to right, #12fff7, #b3ffab);

    margin-right: 2rem;
  }
}
