.blogPostPreview {
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    padding-bottom: 2rem;
    border-bottom: 0.5px solid gray;
  }
  font-family: "Montserrat", sans-serif;

  &:first-child > p:first-of-type {
    margin: 0;
  }

  .authorAvatar {
    height: 5rem;
    width: 5rem;
    border-radius: 50%;
    border: 2px solid orange;
    margin-left: 1rem;
    object-fit: cover;
  }
}

.category {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: darken(gray, 25);
  margin: 0;
  margin-top: 4rem;
}

.title {
  margin: 1.5rem 0;
}

.description {
  margin: 0;
}

.authorName {
  font-weight: bold;
  margin: 0;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  font-style: italic;
}

.dateContainer {
  font-size: 1.4rem;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tagBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  div {
    background-color: #34495e;
    color: white;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    margin-top: 1rem;
  }

  div:not(:last-child) {
    margin-right: 1.5rem;
  }
}

.blogPostWrapper {
  .blogPost {
    padding: 5.5rem 4rem 2rem 4rem;

    pre {
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
      border-radius: 0.5rem;
    }

    .blogPostSubHeading {
      margin: 0;
      margin-bottom: 2rem;
    }

    .postDescription {
      font-style: italic;
      color: darken(gray, 25);
      letter-spacing: 1px;
      line-height: 2rem;
      margin: 0;
      padding: 0 2rem;
    }

    .authorFooter {
      display: flex;
      align-items: center;
      cursor: pointer;

      img {
        height: 5rem;
        width: 5rem;
        border-radius: 50%;
        object-fit: cover;
        margin-left: 1rem;
        border: 2px solid orange;
      }
    }
  }
}

.postHeading {
  display: flex;
  align-items: center;
}

.editBtn {
  margin-left: 3rem;
  user-select: none;
  color: white;
  background-color: black;
  padding: 1.2rem 4rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-size: 2rem;
}
