.ReactMarkdownPreDefinedBlock {
  .math {
    overflow-x: auto;
  }

  img {
    max-width: 100%;
    width: auto;
    height: auto;
  }
}
