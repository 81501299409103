$paddingValue: 10rem;
$orange: #ecf0f1;

.navigation {
  margin: 0 auto;
  position: absolute;
  top: 0;
  height: 80vh;
  width: 100%;
  font-weight: bold;
  font-size: 3rem;
  background: linear-gradient(to right, #ff512f, #f09819);
  color: white;
  font-family: "Baloo Thambi 2", cursive;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.scrolled {
  position: fixed;
  width: 100%;
  height: 7rem;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;

  .leftSide {
    background-color: transparent;
    position: initial;
  }
}

.leftSide {
  padding: 1rem 0 1rem $paddingValue;
  display: flex;
  width: 100%;
  position: fixed;
  align-items: center;
  justify-content: flex-start;
  z-index: 10;
  background-color: rgba($color: black, $alpha: 0.4);
}

.entry {
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  user-select: none;
  &:not(:last-child) {
    margin-right: 7rem;
  }

  &:hover {
    &::before {
      width: 50%;
      opacity: 1;
      visibility: visible;
    }

    &::after {
      width: 50%;
      opacity: 1;
      visibility: visible;
    }
  }

  &::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    height: 0.5rem;
    background-color: $orange;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    right: 50%;
    height: 0.5rem;
    background-color: $orange;
    width: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s;
  }

  a {
    color: white;
    text-decoration: none;
  }
}

.hideCaption {
  display: none;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -80%);
  color: #ecf0f1;
  user-select: none;
}

.captionHeading {
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  letter-spacing: 2px;
}

.captionDescription {
  margin: 0;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .leftSide {
    padding: 1rem 0 1rem 0;
    justify-content: center;
  }
}

@media screen and (max-width: 600px), screen and (max-height: 625px) {
  .leftSide {
    display: none;
  }
}
