.pageWrapper {
  background-color: #ecf0f1;
  width: 100%;
  overflow: hidden;
  height: calc(var(--vh, 1vh) * 100);
  font-family: "Montserrat";
  display: grid;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2 {
    font-size: 7rem;
    letter-spacing: 1rem;
    font-weight: 600;
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  a {
    font-weight: bold;
    color: black;
    text-decoration: underline;
  }
}
