.filter-menu-trans-enter {
  transform: translateY(170rem);
}
.filter-menu-trans-enter-active {
  transform: translateY(0);
  transition: transform 400ms;
}
.filter-menu-trans-exit {
  transform: translateY(0);
}
.filter-menu-trans-exit-active {
  transform: translateY(170rem);
  transition: transform 400ms;
}
